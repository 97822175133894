.login{
    position: fixed;
    top:0px;
    background:linear-gradient(
        103.3deg,
        #f5f5f7 0%,
        #f5f5f7 16.67%,
        #e3ffe071 100%
      );
    height:100vh;
    justify-content: center;
    align-items: center;
}
.margin{
    margin-top:10rem;
}