.icons{
    display: flex;
    justify-content: center;
    align-items: center;
    background:white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 100px;
    width: 80px;
    height: 80px;
    z-index: -1;
}    