.color{
  color: #1688CD;
}

.active-navbar{
  border-bottom: 2px solid #1688CD;
  display: flex;
  padding-bottom:0px !important;

  background-color: none;
  transition: ease-in-out 10ms;
}
.active-navbar-mobile{
  color: #1688CD;
  transition: ease-in-out 10ms;
}
@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin:auto !important;
  }
}
.nav-item{
  font-size: 17px !important;
  text-transform: uppercase;
  padding-left:8px !important;
  padding-right:8px !important;

}

